


































import { Component, Prop } from 'vue-property-decorator'

import { GlobalSearchResult } from '../../../../models'

import { AbstractModuleUiPresentation } from '../../_abstract'

/**
 * Presentational component for the `GlobalSearchResultsModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<GlobalSearchResultsModuleUiPresentation>({
  name: 'GlobalSearchResultsModuleUiPresentation',
  components: {
    Result: () => import(
      /* webpackChunkName: "modules/GlobalSearchResults" */
      './partials/Result/Result.vue'
    ),
    SearchForm: () => import(
      /* webpackChunkName: "modules/GlobalSearchResults" */
      './partials/SearchForm/SearchForm.vue'
    )
  }
})
export class GlobalSearchResultsModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * Array of search results.
   */
  @Prop({ type: Array, required: true })
  public readonly results!: GlobalSearchResult[]

  /**
   * Determines whether there are any results to render.
   */
  public get hasResults (): boolean {
    return Array.isArray(this.results) && this.results.length > 0
  }
}

export default GlobalSearchResultsModuleUiPresentation
