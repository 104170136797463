








import { Component, Mixins, Watch } from 'vue-property-decorator'
import { QueryParams } from '@movecloser/front-core'

import { GlobalSearchRepositoryType, IGlobalSearchRepository } from '../../../../repositories'
import { GlobalSearchResult } from '../../../../models'
import { Loading } from '../../../../extensions'
import { log } from '../../../../support'

import { AbstractModuleUi } from '../../_abstract'

import { GlobalSearchResultsModule } from '../GlobalSearchResults.contracts'

/**
 * Container component for the `GlobalSearchResultsModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<GlobalSearchResultsModuleUi>({
  name: 'GlobalSearchResultsModuleUi',
  components: {
    GlobalSearchResultsModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/GlobalSearchResults" */
      './GlobalSearchResults.ui.presentation.vue'
    ),
    ModuleUnavailablePlaceholder: () => import(
      /* webpackChunkName: "cms/frame" */
      '../../../molecules/ModuleUnavailablePlaceholder/ModuleUnavailablePlaceholder.vue'
    )
  }
})
export class GlobalSearchResultsModuleUi extends Mixins<
  AbstractModuleUi<GlobalSearchResultsModule>, Loading
>(AbstractModuleUi, Loading) {
  /**
   * Array of the search results fetched from the API.
   *
   * @see GlobalSearchResultsModuleUiPresentation.results
   */
  public results: GlobalSearchResult[] = []

  /**
   * Page's query
   */
  public get query (): QueryParams {
    return this.$route.query as QueryParams
  }

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    try {
      await this.fetchResults()
    } catch (error) {
      log(error, 'error')
    }
  }

  /**
   * Fetches the job offers from the API.
   */
  private async fetchResults (): Promise<void> {
    this.markAsLoading()

    try {
      this.results = await this.globalSearchRepository.load(this.$route.query as QueryParams)
    } catch (error) {
      log(error, 'error')
    } finally {
      this.markAsReady()
    }
  }

  private get globalSearchRepository (): IGlobalSearchRepository {
    return this.resolveInjection<IGlobalSearchRepository>(GlobalSearchRepositoryType)
  }

  @Watch('query')
  protected onQueryChanged (): void {
    this.fetchResults()
  }
}

export default GlobalSearchResultsModuleUi
