import { render, staticRenderFns } from "./Result.vue?vue&type=template&id=30899b96&scoped=true&"
import script from "./Result.vue?vue&type=script&lang=ts&"
export * from "./Result.vue?vue&type=script&lang=ts&"
import style0 from "./Result.vue?vue&type=style&index=0&id=30899b96&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30899b96",
  null
  
)

export default component.exports