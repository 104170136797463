





















import { BootstrapButton, BootstrapIcon, BootstrapInput } from '@movecloser/ui-core'
import { Component, Mixins } from 'vue-property-decorator'

import { Loading } from '../../../../../../extensions'
import { log } from '../../../../../../support'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<SearchForm>({
  name: 'SearchForm',
  components: { BootstrapButton, BootstrapIcon, BootstrapInput },
  created () {
    if (typeof this.$route.query.q === 'string') {
      this.formData.query = this.$route.query.q
    }
  }
})
export class SearchForm extends Mixins<Loading>(Loading) {
  /**
   * Data entered by the User in the form.
   */
  public formData: { query: string | null } = { query: null }

  /**
   * Handles the `@submit` event on the search form.
   */
  public onSubmit (): void {
    this.search()
  }

  /**
   * Performs the search action.
   */
  private async search (): Promise<void> {
    this.markAsLoading()

    const query: string = this.formData.query ?? ''

    try {
      await this.$router.push({
        path: this.$route.path,
        query: { q: query }
      })
    } catch (error) {
      const message: string = 'SearchForm.search(): Error during search!'
      log([message, error], 'error')
    } finally {
      this.markAsReady()
    }
  }
}

export default SearchForm
