












import { BootstrapLink } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Result>({
  name: 'Result',
  components: { BootstrapLink }
})
export class Result extends Vue {
  /**
   * Additional excerpt from the page's body.
   */
  @Prop({ type: String, required: false })
  public readonly excerpt?: string

  /**
   * Path for redirect
   */
  @Prop({ type: String, required: true })
  public readonly path!: string

  /**
   * The title of the page.
   */
  @Prop({ type: String, required: false })
  public readonly title?: string

  /**
   * Full URL address of the page.
   */
  @Prop({ type: String, required: true })
  public readonly url!: string

  /**
   * Determines whether the component has been provided with the correct `excerpt` prop.
   */
  public get hasExcerpt (): boolean {
    return typeof this.excerpt === 'string' && this.excerpt.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `title` prop.
   */
  public get hasTitle (): boolean {
    return typeof this.title === 'string' && this.title.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `url` prop.
   */
  public get hasUrl (): boolean {
    return typeof this.url === 'string' && this.url.length > 0
  }
}

export default Result
